import React, { useMemo, useState } from "react"
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import { flex, boxShadow, smoothTransitions } from 'emotion-styled-utils'
import { offset, useFloating, useHover, useInteractions, autoUpdate } from '@floating-ui/react';

import Layout from "../components/layout"
import Image from "../components/image"
import Seo from "../components/seo"
import { parseDate } from '../utils/date'


const Content = styled.div`
  & > h2 {
    font-size: 2rem;
  }
`

const Tooltip = styled.div`
  background-color: ${({ theme }) => theme.tooltip.bgColor};
  color: ${({ theme }) => theme.tooltip.textColor};
  padding: 1em;
  border-radius: 10px;
  font-size: 80%;
`

const Intro = styled.div`
  margin-bottom: 2rem;
  font-size: 1.7rem;
  line-height: 1.3em;

  em {
    font-weight: bolder;
  }
`

const YearList = styled.ul`
  display: block;

  & > li {
    margin-bottom: 2rem;
  }
`

const Year = styled.div`
  ${({ theme }) => theme.font('header')};
  font-size: 1.3rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.investments.year.textColor};
`

const Cat = styled.div`
  ${flex({ direction: 'row', justify: 'flex-start', align: 'flex-start' })};
`

const Stage = styled.div`
  flex: 0;
  font-size: 0.6rem;
  writing-mode: vertical-lr;
  text-transform: uppercase;
  text-align: center;
  min-height: 100px;
  padding: 0 0.2em;
  margin-right: 15px;
  background-color: ${({ theme, stage }) => theme.investments.stage[stage].bgColor};
  color: ${({ theme, stage }) => theme.investments.stage[stage].textColor};
`

const List = styled.ul`
  ${flex({ direction: 'row', justify: 'flex-start', align: 'flex-start', wrap: 'wrap' })};
  flex: 1;
  margin: 0 0 0.5rem;
  font-size: 0.9em;
`

const Inv = styled.li`
  display: block;
  margin: 0 1rem 1rem 0;
  width: 100px;
  height: 100px;
`

const InvAnchor = styled.a`
  ${smoothTransitions()};
  
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px;

  &, &:link, &:visited {
    border: 1px solid ${({ theme }) => theme.investments.anchor.borderColor};
    border-radius: 5px;
  }

  &:hover {
    background-color: transparent;
    border-color: ${({ theme }) => theme.investments.anchor.hoverBorderColor};
    ${({ theme }) => boxShadow({ color: theme.investments.anchor.hoverShadowColor })};
  }
`

const InvImageBgWrapper = styled.div`
  ${flex({ direction: 'column', justify: 'center', align: 'center' })};
  width: 100%;
  height: 100%;
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
`

const InvImageContainer = styled.div`
  width: 75%;
  max-height: 75%;
`

const InvImage = styled(Image)`
  width: 100%;
  height: 100%;
`

const Funds = styled.div`
  margin-top: 1rem;
  border-top: 1px solid ${({ theme }) => theme.investments.funds.borderColor};
  padding-top: 1rem;

  p {
    color: ${({ theme }) => theme.investments.funds.textColor};
    margin-bottom: 1em;
  }
`

const STAGES = {
  'series-x': ['series-a', 'series-b', 'series-c', 'series-d', 'series-e'],
  'safe': ['safe'],
  'seed': ['pre-seed', 'seed', 'seed+'],
  'ico': ['pre-ico', 'ico'],
}

const Item = ({ row }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const {refs, floatingStyles, context} = useFloating({
    placement: 'top',    
    open: tooltipOpen,
    onOpenChange: setTooltipOpen,    
    middleware: [offset(10)]
  });

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([ hover ])

  return (
    <>
      <InvAnchor href={row.profile} ref={refs.setReference} {...getReferenceProps()}>
        <InvImageBgWrapper bgColor={row.imgBg}>
          <InvImageContainer>
            <InvImage src={row.img} />
          </InvImageContainer>
        </InvImageBgWrapper>
      </InvAnchor>
      {tooltipOpen && (
        <Tooltip style={floatingStyles} ref={refs.setFloating} {...getFloatingProps()}>
          {row.name}
        </Tooltip>
      )}
    </>
  )
}

const Page = () => {
  const data = useStaticQuery(graphql`    
    query {
      allInvestment(sort: {fields: date, order: DESC}) {
        nodes {
          name
          profile
          date
          stage
          platform
          category
          img
          imgBg
        }
      }
    }
  `)

  const { investments, years } = useMemo(() => {
    const i = data.allInvestment.nodes.reduce((m, v) => {
      const d = parseDate(v.date)
      d.year = (d.year < 2021) ? '2014-2020' : `${d.year}`
      m[d.year] = m[d.year] || {}
      m[d.year][v.stage] = m[d.year][v.stage] || []
      m[d.year][v.stage].push(v)
      return m
    }, {})

    const years = Object.keys(i).sort((a, b) => a < b ? 1 : -1)

    return { investments: i, years }
  }, [data])

  return (
    <Layout noHeader={true}>
      <Seo />
      <Content>
        <Intro>
          Early-stage angel investor focused on <em>impactful</em> tech.
        </Intro>
        <YearList>
          {years.map(year => (
            <li key={year}>
              <Year>{year}</Year>
              {Object.keys(STAGES).map(stage => {
                const rows = STAGES[stage].reduce((m, s) => {
                  return m.concat(investments[year][s] || [])
                }, [])
                if (rows && rows.length) {
                  return (
                    <Cat key={stage}>
                      <Stage stage={stage}>{stage}</Stage>
                      <List>
                        {rows.map(row => (
                          <Inv key={row.name}><Item row={row} /></Inv>
                        ))}
                      </List>
                    </Cat>
                  )
                } else {
                  return null
                }
              })}
            </li>
          ))}
        </YearList>
        <Funds>
          <p>I have also invested via the following funds:</p>
          <List>
            <Inv>
              <Item row={{ name: 'QVentures', profile: 'https://qventures.co', img: 'qventures.jpeg' }} />
            </Inv>
            <Inv>
              <Item row={{ name: 'Fuel Ventures', profile: 'https://fuel.ventures', img: 'fuelventures.png' }} />            
            </Inv>
          </List>
        </Funds>
      </Content>
    </Layout>
  )
}


const IndexPage = () => (
  <Page />
)

export default IndexPage

